import { render, staticRenderFns } from "./coach.vue?vue&type=template&id=03b6dba7&scoped=true&"
import script from "./coach.vue?vue&type=script&lang=js&"
export * from "./coach.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b6dba7",
  null
  
)

export default component.exports